import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { of, Observable, throwError } from 'rxjs';
import { PolicyRequestsService } from '@nationwide/dgs-internet-servicing-policy-requests';
import { concatMap, catchError, mergeMap } from 'rxjs/operators';
import { WaitSpinnerService } from './wait-spinner.service';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { APICommonService } from '@nationwide/api-common-service';
import { PolicyNumberFormatService } from './policy-number-format.service';
import { LoggerService } from 'app/service/logger.service';

@Injectable({
    providedIn: 'root'
})
export class SelfEnrollmentService {
    policyChangeId: string;
    selfEnrollFlowInPlay: boolean;
    policyResponseChangeId: string;
    /* eslint-disable no-magic-numbers*/
    phoneNumberSliceLimit = -3;

    // eslint-disable-next-line max-params
    constructor(
        public _policyRequest: PolicyRequestsService,
        public _waitSpinnerService: WaitSpinnerService,
        private http: HttpClient,
        private _user: UserService,
        private apiCommonService: APICommonService,
        private logger: LoggerService,
    ) { }

    startMobileEnrollment(policyNumber: string, updatedPhoneNumber: string): Observable<any> {
        const consumerData: any = this.getConsumerData();
        const policyData = {
            apigeeData: consumerData.environment.APIs.apigeeEnvironment,
            service: consumerData.environment.APIs.experienceAutoServicing,
            auto: consumerData.environment.APIs.experienceAuto,
            client_id: consumerData.environment.authorize.client_id

        };
        const phoneNumber = this.formatPhoneNumberForRequest(updatedPhoneNumber);
        const enrollmentStatus = 'Enrolled';
        this._waitSpinnerService.waitMessageUpdate('Please wait while we save your policy change...');
        if (!this.policyChangeId) {
            return this.startPolicyChange(consumerData, policyNumber)
            .pipe(
                concatMap((response: any) => {
                        this.policyChangeId = response.policyChangeId;
                        this._waitSpinnerService.waitMessageUpdate('Please wait while we enroll you in the SmartRide Program...');
                        this.logger.info('Waiting for addMobileEnrollment call response', {
                            policyChangeId: this.policyChangeId,
                            userData: policyData,
                            phoneNumber: phoneNumber.slice(this.phoneNumberSliceLimit)
                          });

                        return this.policyChangeId ? this._policyRequest.addMobileEnrollment({consumerData, phoneNumber, policyChangeId: response.policyChangeId, enrollmentStatus}) : of(response);
                }),
                concatMap((response: any) => {
                    const config: any = {
                        consumerData,
                        policyChangeId: this.policyChangeId
                    };
                    this._waitSpinnerService.waitMessageUpdate('Please wait while we quote your policy change...');
                    this.logger.info('Waiting for ratePolicyChange call response', {
                        userConfig: policyData,
                        policy: this.policyChangeId
                      });
                    return response.mobileEnrollment ? this._policyRequest.ratePolicyChange(config) : of(response);
                }),
                concatMap((response) => {
                    const policyChangeId = {
                        policyChangeId: this.policyChangeId
                    };
                    const policyConfig: any = {
                        consumerData,
                        issuePolicyChangeData: { policyChangeId: this.policyChangeId },
                        policyNumber,
                        skipContentTypeHeader: false
                    };
                    this._waitSpinnerService.waitMessageUpdate('Please wait while we submit your policy change...');
                    this.logger.info('Waiting for issuePolicyChange call response', {
                        config: policyData,
                        updatedPolicy: this.policyChangeId,
                        policy: policyNumber
                      });
                    return response.policyChangeId ? this._policyRequest.issuePolicyChange(policyConfig) : of(response);
                }),
                catchError((error) => {
                    this.logger.error('Error in self enrollment', { error });
                    return of(error);
                }));
        }
    }

    startPolicyChange(consumerData: any, policyNumber: string): Observable<any> {
        if (sessionStorage.getItem('policyChangeId')) {
            const policyChangeId = sessionStorage.getItem('policyChangeId');
            return of({policyChangeId});
        } else {
            return this._policyRequest.startPolicyChange(consumerData, policyNumber, moment(environment.futureDate).format('YYYY-MM-DD'))
            .pipe(
                mergeMap((response: any) => {
                    if (!this.policyChangeId) {
                        this.policyChangeId = response.policyChangeId;
                        return of(response);
                    }
                }));
        }
    }

    checkForMobileEnrollment(): Observable<any> {
        const url: string = environment.personalLinesPolicyService.url;
        const policy = PolicyNumberFormatService.formatPolicyNumber(this._user.getSelfEnrollPolicy());
        const options = this.getOptions();
        const isIncludeMileageData = false;
        return this.http.get(`${url}/policies/${policy}/telematics-enrollments?includeMileageData=${isIncludeMileageData}`, options);
    }

    checkForMobileEnrollmentForAdminSearch(policy): Observable<any> {
        const url: string = environment.personalLinesPolicyService.url;
        const options = this.getOptions();
        const isIncludeMileageData = false;
        return this.http.get(`${url}/policies/${policy}/telematics-enrollments?includeMileageData=${isIncludeMileageData}`, options);
    }

    getOptions(): { headers: HttpHeaders } {
        let headers = new HttpHeaders();
        headers = headers.append('client_id', environment.apiKey);
        headers = headers.append('X-NW-Message-ID', this.apiCommonService.generateTransactionId());
        headers = headers.append('X-NW-Target-Env', environment.personalLinesPolicyService.xNwTargetEnv);
        if (environment.production) {
            headers = headers.delete('X-NW-Target-Env');
        }
        return { headers };
    }

    getConsumerData(): any {
        const consumerData = {
            acessToken: sessionStorage.getItem('tokenDetails'),
            environment: {
                APIs: {
                    apigeeEnvironment: environment.apigeeExtHostnameURL,
                    experienceAutoServicing: environment.selfEnrollmentService.experienceAutoServicing,
                    experienceAuto: environment.selfEnrollmentService.experienceAuto
                },
                authorize: {
                    /* eslint-disable camelcase*/
                    client_id: environment.apiKey
                },
                skipContentTypeHeader: false,
                policyCenterShortName: environment.personalLinesPolicyService.xNwTargetEnv
            }
        };
        if (environment.production) {
            delete consumerData.environment.policyCenterShortName;
        }
        return consumerData;
    }

    formatPhoneNumberForRequest(phoneNumber: string): string {
        let rawPhoneNumber = phoneNumber.replace('(', '');
        rawPhoneNumber = rawPhoneNumber.replace(')', '');
        rawPhoneNumber = rawPhoneNumber.replace('-', '');
        rawPhoneNumber = rawPhoneNumber.replace(' ', '');
        return rawPhoneNumber.replace(/(\d{3})-?(\d{3})-?(\d{4})/, '$1-$2-$3');
    }

    set isSelfEnrollFlowInPlay(flowInPlay: boolean) {
        this.selfEnrollFlowInPlay = flowInPlay;
    }

    get isSelfEnrollFlowInPlay(): boolean {
        return this.selfEnrollFlowInPlay;
    }

    getPolicyEligibleDiscounts(): any {
        const consumerData: any = this.getConsumerData();
        const policyNumber: string = this._user.getSelfEnrollPolicy();
        return this._policyRequest.startPolicyChange(consumerData, policyNumber, moment(environment.futureDate).format('YYYY-MM-DD'))
        .pipe(
          mergeMap((response: any) => {
            if (!this.policyResponseChangeId) {
              this.policyResponseChangeId = response.policyChangeId;
              sessionStorage.setItem('policyChangeId', this.policyResponseChangeId);
              return this.policyResponseChangeId ? this._policyRequest.retrieveAllPolicyEligibleDiscountsByChangeId(consumerData, response.policyChangeId) : throwError(response);
          }
        }));
    }
}
